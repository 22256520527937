import React from 'react'

import Typography from '@eig-builder/core-ui/Typography'
import Domain from './domain-renewal.styled'

const DomainRenewal = () => (
  <Domain>
    <Domain.section>
      <Typography variant='h1' gutterBottom>
        Sitebuilder Domain Name Renewal Notification Policy
      </Typography>
    </Domain.section>
    <Typography variant='body1' gutterBottom>
      For domain registration services, email reminders are sent approximately
      one month prior to the domain expiration date and again approximately one
      week prior to the domain expiration date. If the domain is not renewed by
      the expiration date, an additional email notification will be sent within
      five days of the domain expiration date. All email reminders are sent to
      the registrant email address listed for the domain name.
    </Typography>
  </Domain>
)

export default DomainRenewal
