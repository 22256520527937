import styled from 'styled-components'

const Domain = styled.div`
  padding-top: 60px;
`
Domain.section = styled.div`
  padding-bottom: 30px;
`

export default Domain
